// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-careers-js": () => import("./../src/pages/about/careers.js" /* webpackChunkName: "component---src-pages-about-careers-js" */),
  "component---src-pages-about-our-team-js": () => import("./../src/pages/about/our-team.js" /* webpackChunkName: "component---src-pages-about-our-team-js" */),
  "component---src-pages-articles-bcg-article-js": () => import("./../src/pages/articles/BCG-article.js" /* webpackChunkName: "component---src-pages-articles-bcg-article-js" */),
  "component---src-pages-articles-st-article-js": () => import("./../src/pages/articles/ST-article.js" /* webpackChunkName: "component---src-pages-articles-st-article-js" */),
  "component---src-pages-get-started-js": () => import("./../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-work-components-data-card-js": () => import("./../src/pages/ourWork/components/DataCard.js" /* webpackChunkName: "component---src-pages-our-work-components-data-card-js" */),
  "component---src-pages-our-work-components-horizontal-com-js": () => import("./../src/pages/ourWork/components/HorizontalCom.js" /* webpackChunkName: "component---src-pages-our-work-components-horizontal-com-js" */),
  "component---src-pages-our-work-components-images-list-js": () => import("./../src/pages/ourWork/components/ImagesList.js" /* webpackChunkName: "component---src-pages-our-work-components-images-list-js" */),
  "component---src-pages-our-work-components-vertical-com-js": () => import("./../src/pages/ourWork/components/VerticalCom.js" /* webpackChunkName: "component---src-pages-our-work-components-vertical-com-js" */),
  "component---src-pages-our-work-detail-js": () => import("./../src/pages/ourWork/detail.js" /* webpackChunkName: "component---src-pages-our-work-detail-js" */),
  "component---src-pages-our-work-our-work-js": () => import("./../src/pages/ourWork/our-work.js" /* webpackChunkName: "component---src-pages-our-work-our-work-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-influencer-marketing-js": () => import("./../src/pages/services/influencer-marketing.js" /* webpackChunkName: "component---src-pages-services-influencer-marketing-js" */),
  "component---src-pages-services-social-media-management-js": () => import("./../src/pages/services/social-media-management.js" /* webpackChunkName: "component---src-pages-services-social-media-management-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

